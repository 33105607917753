<template>
    <div class="index">
        <div class="index_head">
            <img style="width: 100%;" src="@/assets/index/banner_new_logo.png" alt=""> 
        </div>
        <div class="index_notice">
            <div class="index_notice_title"><van-icon name="volume-o" /> 活动预告：</div>
            <van-notice-bar
            style="width: 100%;margin: 0 10px 0 0; height: 30px;"
                color="#000"
                background="#fff"
                text="2022年8月1日在中昊慧达科技发展有限公司举行启动仪式"
            />
        </div>
        <div class="index_content">
        <div class="index_content_fqzz" @click="skip('/introductory')">
            <div class="index_content_title">
                商会简介
            </div>
            <div class="index_content_english">
                INTRODUTION
            </div>
            <div class="index_content_img">
                <img style="width:75px" src="@/assets/index/1.png" alt="">
            </div>
        </div>
        <div class="index_content_zzjl index_content_click"  @click="skip('/initiationApply')">
            <div class="index_content_title">
                入会申请
            </div>
            <div class="index_content_english">
                APPLY
            </div>
            <div class="index_content_img">
                <img src="@/assets/index/2.png" alt="">
            </div>
        </div>
    </div>
    <div class="index_content index_content_click">
        <div class="index_content_cjcf" @click="skip('/buildOrder')">
            <div class="index_content_title">
                党建工作
            </div>
            <div class="index_content_english">
                PARTY <br>
                CONSTRUCTION
            </div>
            <div class="index_content_img_special">
                <img src="@/assets/index/3.png" alt="">
            </div>
        </div>
        <div class="index_content_cflb" @click="skip('/publicCharity')">
            <div class="index_content_title">
                公益慈善
            </div>
            <div class="index_content_english">
                PUBLIC <br>
                WELFARE
            </div>
            <div class="index_content_img_special">
                <img src="@/assets/index/4.png" alt="">
            </div>
        </div>
        <!-- <div class="index_content_hzlb" @click="skip('/addressBook')">
            <div class="index_content_title">
                通讯录
            </div>
            <div class="index_content_english">
                ADDRESS BOOK
            </div>
            <div class="index_content_img_special">
                <img src="@/assets/index/5.png" alt="">
            </div>
        </div> -->
    </div>
    <moduleHead module_head_title="邢商风采" @more_func="XSFC_more_func"></moduleHead>
    <div class="xsfc">
        <div class="xsfc_son" v-for="(item,key) in xsfcDataArr" :key="key">
            <img :src="item['img']" alt="">
            <div class="xsfc_son_position" v-html="item['position']"></div>
            <div class="xsfc_son_name">{{item['name']}}</div>
        </div>
    </div>
    <div class="xsfc_img">
        <img style="width: 100%;" src="@/assets/index/tu.png" alt="">
    </div>
    <moduleHead module_head_title="商会动态" @more_func="SHDT_more_func"></moduleHead>
    <div class="shdt">

        <div class="shdt_son" v-for="(item,key) in press_data" :key="key" @click="skip('/dynamicallyDetails',item)">
            <img :src=item.list_img alt="">
            <div class="shdt_son_text">
                <div class="shdt_son_text_introduce">{{item.list_text}}</div>
                <div class="shdt_son_text_bottom">
                    <div>{{item.list_firmName}}</div>
                    <div>{{item.list_date}}</div>
                </div>
            </div>
        </div>

        <!-- <div class="shdt_son">
            <img src="@/assets/index/ee2.jpg" alt="">
            <div class="shdt_son_text">
                <div class="shdt_son_text_introduce">秦皇岛邢台商会、秦皇岛格林布瑞医疗器械设备有限公司战略合作签约仪式圆满举办秦皇岛邢台商会、秦皇岛格林布瑞医疗器械设备有限公司战略合作签约仪式圆满举办</div>
                <div class="shdt_son_text_bottom">
                    <div>秦皇岛市邢台商会</div>
                    <div>2022-08-03</div>
                </div>
            </div>
        </div>

        <div class="shdt_son">
            <img src="@/assets/index/ee3.jpg" alt="">
            <div class="shdt_son_text">
                <div class="shdt_son_text_introduce">秦皇岛邢台商会、秦皇岛格林布瑞医疗器械设备有限公司战略合作签约仪式圆满举办秦皇岛邢台商会、秦皇岛格林布瑞医疗器械设备有限公司战略合作签约仪式圆满举办</div>
                <div class="shdt_son_text_bottom">
                    <div>秦皇岛市邢台商会</div>
                    <div>2022-08-03</div>
                </div>
            </div>
        </div> -->

    </div>
    </div>
</template>

<script>
import {
  defineComponent,reactive,store
} from "@/plugin/importCommonUse";
import { useRouter } from "vue-router";
import moduleHead from "@/components/moduleHead/index.vue"
import { concat } from "lodash";
export default defineComponent({
    components:{
        moduleHead
    },
    setup() {
        let router = useRouter();
        let xsfcDataArr = reactive([
            {
                img:require("@/assets/index/pian.png"),
                position:"会长",
                name:"杨建忠"
            },
            {
                img:require("@/assets/index/hw.png"),
                position:"常务副会长",
                name:"胡伟"
            },
            {
                img:require("@/assets/index/gzj.png"),
                position:`秘书长<br>常务副会长`,
                name:"耿占杰"
            },
            {
                img:require("@/assets/index/xwq.png"),
                position:"常务副会长",
                name:"辛文秋"
            },
            {
                img:require("@/assets/index/csw.png"),
                position:"常务副会长",
                name:"陈士卫"
            },
            {
                img:require("@/assets/index/gjf.png"),
                position:"常务副会长",
                name:"郭建峰"
            },
            {
                img:require("@/assets/index/ljx.png"),
                position:"常务副会长",
                name:"李建勋"
            },
        ])
        let skip = (url,data = false)=>{
            router.push(url);
            console.log(data['list_detailsHTML'])
            if(data){
                localStorage.setItem('list_detailsHTML',data['list_detailsHTML'])
            }
        }
        let XSFC_more_func = ()=>{
            router.push("/XingtaiShow");
        }
        let SHDT_more_func = () =>{
            console.log("商会动态")
        }
        let press_data = store.state.press_data;
        return {
            skip,XSFC_more_func,xsfcDataArr,SHDT_more_func,press_data
        };
    },
})
</script>
<style>
.index{
    width: 100%;
    /* border-bottom: 1px solid #dcdee2; */
}

._center img{
    border-radius:100px;
    box-shadow: var(--el-box-shadow-light);
}
._center div{
    margin-top: 5px;
}
.index_notice{
    margin: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    align-items: center;
        border-radius: 12px;
        height: 30px;
}
.index_notice_title{
    white-space: nowrap;
    margin: 0 0 0 10px;
    color: red;
    font-size: 15px;
}
.van-notice-bar{
        padding: 0 !important;
}

.index_content{
        width: 97%;
        display: flex;
        margin: 0.2% 1.5%;
    }
    /* 发起转诊 */
    .index_content_fqzz{
        width: 64.66%;
        height: 120px;
        background:#F8EEED;
        margin: 0.9%;
    }
    /* 转诊记录 */
    .index_content_zzjl{
        width: 32.33%;
        height: 120px;
        background: #FAF5EF;
        margin: 0.9%;
    }
    /* 创建处方 */
    .index_content_cjcf{
        width: 31.33%;
        height: 120px;
        background: #EFF3FE;
        margin: 0.9%;
    }
    /* 处方列表 */
    .index_content_cflb{
        width: 31.33%;
        height: 120px;
        background: #ECF6F5;
        margin: 0.9%;
    }
    /* 患者列表 */
    .index_content_hzlb{
        width: 32.33%;
        height: 120px;
        background: #F3F7E8;
        margin: 0.9%;
    }
    /* 个人中心 */
    .index_content_grzx{
        width: 64.66%;
        height: 120px;
        background:#E7F4FC;
        margin: 0.9%;
    }
    /* 更多 */
    .index_content_gd{
        width: 32.33%;
        height: 120px;
        background: #DFF1FF;
        margin: 0.9%;
    }
    .index_content_title{
        text-align: left;
        color: #000;
        margin: 10px 0 0 10px;
        font-size: 15px;
        font-weight: 600;
    }
    .index_content_english{
        text-align: left;
        color: #999999;
        margin: 10px 0 0 10px;
        font-size: 12px;
    }
    .index_content_img{
        text-align: right;
        margin: 10px 10px 0 0;
    }
    .index_content_img img{
        width: 50px;
    }
    .index_content :active{
        /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
        /* background: #f7f7f7; */
        opacity: 0.6;
    }
    .index_content_img_special{
        text-align: right;
        margin: 0px 10px 0 0;
    }
    .index_content_img_special img{
        width: 50px;
    }
    .xsfc{
        display: flex;
        overflow: auto;
        /* overflow-x: hidden; */
    }
    /* @keyframes fadenum{
        0%{transform:translateX(10px);}
        50%{transform:translateX(-140px);}
        100%{transform:translateX(10px);}
    } */
    ::-webkit-scrollbar{display: none;}
    .xsfc_son{
        width: 109px;
        margin: 0 8px;
        position: relative;
        animation:fadenum 40s infinite linear;
    }
    .xsfc_son img{
        width: 110px;
    }
    .xsfc_son_position{
        width: 82%;
        display: flex;
        margin: auto;
        align-items: center;
        height: 34px;
        line-height: 16px;
        font-size: 14px;
        color: #fff;
        justify-content: center;
        background: #E13C3A;
        position: absolute;
        bottom: 22px;
        left: 9%;
        border-top-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }
    .xsfc_son_name{
        margin-top: 10px;
        font-size: 15px;
    }
    .xsfc_img{
        margin: 10px 8px;
    }
    .shdt{
        margin: 10px 8px;
    }
    .shdt_son{
        width: 100%;
        display: flex;
        margin: 0px 0 25px 0; 
    }
    .shdt_son img{
        width: 33%;
        height: 71px;
    }
    .shdt_son_text{
        width: 66%;
        height: 70px;
        /* height: 100px; */
        margin-left: 1%;
        border-bottom: 1px #9A9A9A solid;
    }
    .shdt_son_text_introduce{
        text-align: left;
        letter-spacing:1.7px;
        font-size: 14px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 1.5;					
        -webkit-box-orient: vertical;
    }
    .shdt_son_text_bottom{
        display: flex;
        font-size: 12px;
        color: #9A9A9A;
        justify-content: space-between;
        margin-top: 20px;
        margin-left: 6px;
        margin: 10px 0px 20px 0px; 
    }
</style>
